<template>
  <div
    v-if="isPassenger"
    class="sign-up-email-wrapper">
    <v-container grid-list-xl>
      <v-btn
        class="btn-back"
        flat
        icon
        @click="stepBack()"
      >
        <v-icon color="dark">
          arrow_back
        </v-icon>
      </v-btn>
      <div class="custom-container">
        <div>
          <div class="custom-title black--text mb-3">Vul je e-mailadres in</div>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit">
            <v-text-field
              v-if="!$store.getters.getAddToIosState"
              v-model="userData.email"
              :rules="emailRules"
              color="black"
              background-color="ashyGray"
              class="custom-input py-0"
              type="email"
              placeholder="E-mail"
              required
            />
          </v-form>
          <div>
            <v-btn
              :loading="loginLoader"
              class="btn-submit white--text"
              color="black"
              block
              @click="submit()">
              Volgende
              <v-icon>arrow_forward</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-container>
  </div>
  <div
    v-else
    class="full-height">
    <div
      :style="{ background: loginScreenStyle.background }"
      class="session-wrapper login-screen column-layout justify-center background-cover">
      <v-container
        grid-list-xl
        class="column-layout justify-center">
        <div class="text-xs-center">
          <div class="session-table-cell row-layout-center">
            <div class="session-content login-content">
              <div class="login-logo text-xs-left">
                <img
                  :src="require('../../../assets/images/logo.svg')"
                  alt="Drive Me">
              </div>

              <div class="white-18 text-upper text-xs-left mt-5">
                maak een account aan en boek je rit
              </div>

              <div class="white-14 text-xs-left mt-4">
                Het aanmaken van een DriveMe account verplicht je tot niets.
                Kijk gerust even rond.
              </div>

              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pt-2 mt-3"
                @submit.prevent="submit">
                <v-text-field
                  v-if="!$store.getters.getAddToIosState"
                  v-model="userData.email"
                  :rules="emailRules"
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  class="red-validation mt-3"
                  type="email"
                  label="E-mail"
                  dark
                  required
                />
                <div class="mt-3">
                  <v-btn
                    v-if="!$store.getters.getAddToIosState"
                    id="passenger-signup-email"
                    :disabled="!valid"
                    :loading="loginLoader"
                    large
                    block
                    round
                    dark
                    color="pink"
                    class="login-button"
                    @click="submit">Volgende</v-btn>
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </v-container>
    </div>

  </div>
</template>

<script>
import request from '../../../helpers/request'
import { TEXT_FIELD_MAX_LENGTH } from '../../../constants/common'
import { getEnvVersion } from '../../../helpers/helpers'

export default {
  mixins: [request],
  props: {
    userData: {
      type: Object,
      default: () => { return {} }
    },
    prevStep: {
      type: Number,
      default: () => { return null }
    }
  },
  data () {
    return {
      TEXT_FIELD_MAX_LENGTH,
      valid: false,
      emailRules: [],
      loginLoader: false,
      isPassenger: false
    }
  },
  computed: {
    loginScreenStyle () {
      return {
        background: `linear-gradient(to bottom, ${this.$store.getters.colorGuide.login.gradient.from} 40vh, ${this.$store.getters.colorGuide.login.gradient.to} 100%), url(${this.$store.getters.colorGuide.login.image}) ${this.$vuetify.breakpoint.xsOnly ? this.$store.getters.colorGuide.login.imageParams : this.$store.getters.colorGuide.login.imageParamsDesktop}`
      }
    }
  },
  watch: {
    'userData.email' (val) {
      this.emailRules = []
    }
  },
  beforeMount () {
    this.isPassenger = getEnvVersion().siteVersion === 'ROLE_PASSENGER'
    const secretaryEmail = localStorage.getItem('secretaryEmail')

    if (secretaryEmail) {
      this.$emit('updateUserData', { param: 'email', val: secretaryEmail })

      let body = {
        data: {
          email: secretaryEmail
        }
      }

      this.request('PATCH', '/sign-up', body, ({ data }) => {
        if (this.prevStep === 3) {
          this.$emit('prevtStep')
        } else {
          this.$emit('nextStep')
        }
      })
    }
  },
  methods: {
    submit () {
      this.emailRules = [
        v => !!v || 'Is verplicht',
        v => v.length > 6 || 'Vul je e-mailadres in'
      ]

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          let body = {
            data: {
              email: this.userData.email
            }
          }

          this.request('PATCH', '/sign-up', body, ({ data }) => {
            this.$emit('nextStep')
          })
        }
      })
    },
    stepBack () {
      window.location.href = '/signup#step=1'
    }
  }
}
</script>

<style lang="scss" scoped>
    .login-content {
        width: 90%;
        max-width: 350px;
    }
    .login-logo {
        margin-bottom: 5%;
        margin-top: 40px;
    }
    .background-cover {
        background-size: cover !important;
    }

    .sign-up-email-wrapper {
      height: 100%;
      background-color: white;

      .btn-back {
        position: absolute;
        top: 8px;
        left: 8px;
      }

      .container {
        height: 100%;

        .custom-container {
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
</style>
