/*
 * Change url template by params
 */
export const getUrl = (url, params) => {
  let newURL = url
  const char = '%25%25' // %%
  Object.keys(params).forEach((param) => {
    newURL = newURL.replace(char + param + char, params[param])
  })
  return newURL
}

export const getObjectFromDecodedString = (string) => {
  const params = decodeURI(string)
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"')
  let object = {}

  if (params) {
    object = JSON.parse(`{"${params}"}`)
  }

  return object
}

export const getDecodedStringFromObject = (object) =>
  Object
    .keys(object)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(object[k])}`)
    .join('&')

export const getUrlParamsFromObject = ({ paramsObject, pathname }) => {
  const string = getDecodedStringFromObject(paramsObject)

  return window.location.origin +
    (pathname || window.location.pathname) +
    (string ? `?${string}` : '') +
    window.location.hash
}

export const getObjectFromUrlParams = () => {
  const search = window.location.search.substring(1)

  return getObjectFromDecodedString(search)
}

export const clearUrlParameters = () => {
  const paramsObject = getObjectFromUrlParams()

  if (Object.keys(paramsObject).length) {
    Object
      .keys(paramsObject)
      .forEach(param => {
        delete paramsObject[param]
      })

    const newUrl = getUrlParamsFromObject({ paramsObject })

    setTimeout(() => {
      window.history.pushState(null, null, newUrl)
    }, 3000)
  }
}

export const getHashParams = () => {
  const currentHash = window.location.hash.substring(1)
  const params = getObjectFromDecodedString(currentHash)

  return params || {}
}

export const getHashParamValue = (name) => getHashParams()[name]

export const addHashParamToUrl = (name, value) => {
  const params = getHashParams(name)

  if (value) {
    params[name] = value
  } else {
    delete params[name]
  }

  const newHash = getDecodedStringFromObject(params)

  window.location.hash = newHash ? `#${newHash}` : ''
}
