<template>
  <div
    v-if="isPassenger"
    class="sign-up-phone">
    <v-container grid-list-xl>
      <v-btn
        v-if="![PHONE_SCREENS.VERIFY_PHONE_NEW_USER, PHONE_SCREENS.VERIFY_PHONE_EXISTED_USER].includes(currentScreen)"
        class="btn-back"
        flat
        icon
        @click="$emit('stepBack')"
      >
        <v-icon color="dark">
          arrow_back
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        class="btn-back"
        flat
        icon
        @click="goBack"
      >
        <v-icon color="dark">
          arrow_back
        </v-icon>
      </v-btn>
      <div class="custom-container">
        <div>
          <div v-if="currentScreen === PHONE_SCREENS.ENTER_PHONE">
            <div class="custom-title black--text">Voer je mobiele nummer in</div>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent>
              <div class="phone-field">
                <v-text-field
                  v-model="userData.phone"
                  color="black"
                  background-color="ashyGray"
                  class="custom-input py-0"
                  type="tel"
                  placeholder="06-12345678"
                  required
                  hide-details
                  @keyup.enter="validateForm()"/>
              </div>
            </v-form>
            <div class="custom-small-text black--text mb-2">We sturen je hierna een sms bericht met een verificatiecode</div>
          </div>

          <div v-if="currentScreen === PHONE_SCREENS.VERIFY_PHONE_NEW_USER">
            <div class="custom-title black--text mb-3 mt-0">
              We hebben je een sms <br> gestuurd met een <br>verificatie code
            </div>
            <div class="black--text phone-holder mb-3">
              {{ userData.phone }}
              <div @click="goBack">
                <img
                  class="edit-phone"
                  width="20"
                  src="../../../assets/icons/edit-icon.jpg"
                  alt="Edit">
              </div>
            </div>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent>
              <v-text-field
                v-model="verificationCode"
                :max-length="TEXT_FIELD_MAX_LENGTH"
                color="black"
                background-color="ashyGray"
                class="custom-input py-0"
                type="tel"
                placeholder="0000"
                required
                @keyup.enter="validateForm()"
              />
            </v-form>
            <div class="custom-small-text black--text mb-2">
              Je kunt een nieuwe code aanvragen over {{ countDown }} seconden
            </div>
          </div>

          <div v-if="currentScreen === PHONE_SCREENS.VERIFY_PHONE_EXISTED_USER">
            <div class="custom-title black--text mb-3 mt-0">
              We hebben je een sms <br> gestuurd met een <br>verificatie code
            </div>
            <div class="black--text phone-holder mb-3">
              {{ userData.phone }}
              <div @click="goBack">
                <img
                  class="edit-phone"
                  width="20"
                  src="../../../assets/icons/edit-icon.jpg"
                  alt="Edit">
              </div>
            </div>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent>
              <v-text-field
                v-model="verificationCode"
                color="black"
                background-color="ashyGray"
                class="custom-input py-0"
                type="tel"
                placeholder="0000"
                required
                @keyup.enter="validateForm()"
              />
            </v-form>
            <div class="custom-small-text black--text mb-2">
              Je kunt een nieuwe code aanvragen over {{ countDown }} seconden
            </div>
          </div>

          <div>
            <v-btn
              class="btn-submit white--text"
              color="black"
              block
              @click="validateForm()">
              Volgende
              <v-icon>arrow_forward</v-icon>
            </v-btn>
            <div
              v-if="countDown === 0"
              class="text-center">
              <a
                href="#"
                class="custom-link lightBlue--text mt-3"
                @click="(event) => onGetSmsClick(event)">Code opnieuw sturen</a>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
  <div v-else>
    <blu-bar-card
      :back-text="stepsData[currentScreen].title"
      :is-show-back-icon="false"
      :button-text="stepsData[currentScreen].submitMainText"
      show-info-block
      back-button-id="signup-telephone-back"
      submit-button-id="signup-telephone-submit"
      @submitButton="validateForm()"
      @stepBack="$emit('stepBack')"
    >
      <template>
        <div>
          <v-form
            v-if="currentScreen === PHONE_SCREENS.ENTER_PHONE"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="">
            <v-text-field
              v-model="userData.phone"
              :max-length="TEXT_FIELD_MAX_LENGTH"
              class="red-validation pt-0 underline-input-black mb-5 pb-5"
              color="darkBlue"
              type="text"
              label="Telefoonnummer"
              required
              @keyup.enter="validateForm()"
            />
          </v-form>
          <v-form
            v-if="currentScreen === PHONE_SCREENS.VERIFY_PHONE_NEW_USER"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="">
            <v-text-field
              v-model="verificationCode"
              :max-length="TEXT_FIELD_MAX_LENGTH"
              class="red-validation pt-0 underline-input-black mb-5 pb-5"
              color="darkBlue"
              type="number"
              label="SMS verificatie Code"
              required
            />
          </v-form>
          <v-form
            v-if="currentScreen === PHONE_SCREENS.VERIFY_PHONE_EXISTED_USER"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="">
            <v-text-field
              v-model="verificationCode"
              :max-length="TEXT_FIELD_MAX_LENGTH"
              class="red-validation pt-0 underline-input-black mb-5 pb-5"
              color="darkBlue"
              type="number"
              label="SMS inlog Code"
              required
            />
          </v-form>
        </div>
      </template>
      <template v-slot:under-button>
        <div
          id="signup-telephone-skip"
          :style="stepsData[currentScreen].submitSecondaryTextUnderline ? { textDecoration: 'underline' } : {}"
          class="text-xs-center c-pointer"
          @click="stepsData[currentScreen].onSecondaryClick">
          {{ stepsData[currentScreen].submitSecondaryText }}
        </div>
      </template>
    </blu-bar-card>
  </div>
</template>

<script>
import BluBarCard from '../../../modules/layout/BlueBarCard.vue'
import request from '../../../helpers/request'
// import { storeToken } from '../../../helpers/tokenHelper'

import { TEXT_FIELD_MAX_LENGTH } from '../../../constants/common'
import { getEnvVersion } from '../../../helpers/helpers'

const PHONE_SCREENS = {
  ENTER_PHONE: 'ENTER_PHONE',
  VERIFY_PHONE_EXISTED_USER: 'VERIFY_PHONE_EXISTED_USER',
  VERIFY_PHONE_NEW_USER: 'VERIFY_PHONE_NEW_USER'
}

export default {
  components: { BluBarCard },
  mixins: [request],
  props: {
    userData: {
      type: Object,
      default: () => { return {} }
    },
    ride: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      verificationCode: '',
      stepsData: {
        [PHONE_SCREENS.ENTER_PHONE]: {
          title: 'Voer je mobiele telefoonnummer in',
          input: {
            label: 'Telefoonnummer'
          },
          submitMainText: 'Volgende',
          // submitSecondaryText: 'Inloggen met je e-mail adres',
          onSecondaryClick: () => {},
          submitSecondaryTextUnderline: false
        },
        [PHONE_SCREENS.VERIFY_PHONE_EXISTED_USER]: {
          title: 'Je hebt al een account op dit nummer. We hebben je een sms gestuurd met je inlogcode',
          description: 'Vul hieronder de code uit je sms in',
          submitMainText: 'Inloggen',
          onSecondaryClick: this.getSMSCodeExistedUser,
          submitSecondaryText: 'Geen code ontvangen? Opnieuw proberen',
          submitSecondaryTextUnderline: true
        },
        [PHONE_SCREENS.VERIFY_PHONE_NEW_USER]: {
          title: 'We hebben je een sms gestuurd met een verificatie code',
          submitMainText: 'Verifieer code',
          onSecondaryClick: this.getSMSCode,
          submitSecondaryText: 'Geen code ontvangen? Opnieuw proberen',
          submitSecondaryTextUnderline: true
        }
      },
      PHONE_SCREENS,
      currentScreen: PHONE_SCREENS.ENTER_PHONE,
      TEXT_FIELD_MAX_LENGTH,
      valid: true,
      nameRules: [
        v => !!v || 'Is verplicht',
        v => (v != null && v.length > 0) || 'Vul je Telefoonnummer in'
      ],
      isPassenger: false,
      countDown: 14
    }
  },
  watch: {
    currentScreen (val) {
      this.countDownTimer()
      setTimeout(() => {
        this.countDown = 14
      }, 500)
    }
  },
  beforeMount () {
    this.isPassenger = getEnvVersion().siteVersion === 'ROLE_PASSENGER'
  },
  methods: {
    setPhoneScreen (screen) {
      this.currentScreen = screen
    },
    sendPhone () {
      return new Promise((resolve, reject) => {
        const body = {
          data: {
            phone: this.userData.phone
          }
        }

        this.requestNoAuth('POST', '/sign-up', body, ({ data }) => {
          // storeToken(data)

          let dataUserWithPhone = {
            ...data.user,
            phone: this.userData.phone
          }

          this.constructRequests()

          this.$store.dispatch('loginUser', { data: dataUserWithPhone })
          this.$store.dispatch('setUser', dataUserWithPhone)

          this.$emit('update:userData', dataUserWithPhone)

          resolve(data)
        }, (error) => {
          reject(error)
        })
      })
    },
    getSMSCode () {
      return new Promise(resolve => {
        const body = {
          data: {
            phone: this.userData.phone
          }
        }

        this.request('POST', '/phone/verify', body, ({ data }) => {
          resolve(data)
        })
      })
    },
    getSMSCodeExistedUser () {
      return new Promise(resolve => {
        const body = {
          data: {
            phone: this.userData.phone
          }
        }

        this.requestNoAuth('POST', '/phone/token', body, ({ data }) => {
          resolve(data)
        })
      })
    },
    sendSMSCodeNewUser () {
      return new Promise((resolve, reject) => {
        const body = {
          data: {
            phone: this.userData.phone,
            code: this.verificationCode
          }
        }

        this.request('POST', '/phone/verify/code', body, ({ data }) => {
          resolve(data)
        })
      })
    },
    sendSMSCodeExistedUser () {
      return new Promise(resolve => {
        const body = {
          data: {
            phone: this.userData.phone,
            code: this.verificationCode
          }
        }

        this.requestNoAuth('POST', '/phone/token/code', body, ({ data }) => {
          resolve(data)
        })
      })
    },
    async validateForm () {
      if (this.currentScreen === PHONE_SCREENS.ENTER_PHONE) {
        try {
          await this.sendPhone()
          await this.getSMSCode()

          return this.setPhoneScreen(PHONE_SCREENS.VERIFY_PHONE_NEW_USER)
        } catch (error) {
          if (error.type === 'phone.not_unique') {
            await this.getSMSCodeExistedUser()

            return this.setPhoneScreen(PHONE_SCREENS.VERIFY_PHONE_EXISTED_USER)
          }
        }
      }

      if (this.currentScreen === PHONE_SCREENS.VERIFY_PHONE_EXISTED_USER) {
        try {
          const data = await this.sendSMSCodeExistedUser()
          // storeToken(data)

          let dataUserWithPhone = {
            ...data.user,
            phone: this.userData.phone
          }

          this.constructRequests()

          this.$store.dispatch('loginUser', { data: dataUserWithPhone })
          this.$store.dispatch('setUser', dataUserWithPhone)

          this.$emit('update:userData', dataUserWithPhone)
          window.location.reload()
        } catch (error) {
          console.log(error)
        }
      }

      if (this.currentScreen === PHONE_SCREENS.VERIFY_PHONE_NEW_USER) {
        if (this.userData.phone && this.verificationCode) {
          try {
            await this.sendSMSCodeNewUser()
            this.$emit('nextStep')
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    countDownTimer () {
      if (this.countDown > 0 && (this.currentScreen === PHONE_SCREENS.VERIFY_PHONE_NEW_USER || this.currentScreen === PHONE_SCREENS.VERIFY_PHONE_EXISTED_USER)) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    goBack () {
      this.currentScreen = PHONE_SCREENS.ENTER_PHONE
    },
    async onGetSmsClick (event) {
      event.preventDefault()
      try {
        await this.getSms()
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'SMS-code is opnieuw verzonden'
        })
        this.countDown = 14
        this.countDownTimer()
      } catch (error) {
        console.log(error)
      }
    },
    getSms () {
      return new Promise(resolve => {
        const body = {
          data: {
            phone: this.userData.phone
          }
        }

        this.request('POST', '/phone/verify', body, ({ data }) => {
          resolve(data)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .sign-up-phone {
    height: 100%;
    background-color: white;

    .btn-back {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    .container {
      height: 100%;

      .custom-container {
        min-height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .phone-holder {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            display: flex;
            margin-left: 10px;
          }
        }
        .phone-field {
          margin: 20px 0;
        }
      }

      @media not all and (min-resolution:.001dpcm) {
        @supports (-webkit-appearance:none) {
          .custom-container {
            min-height: -webkit-fill-available !important;
          }
        }
      }
    }
  }
</style>
