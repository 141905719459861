<template>
  <div
    v-if="isPassenger"
    class="sign-up-wrapper">
    <v-container grid-list-xl>
      <div class="custom-container text-center">
        <div>
          <div>
            <img
              src="../../../assets/images/logo.svg"
              alt="Logo">
          </div>
          <div>
            <img
              class="intro-img"
              src="../../../assets/images/get-started.jpg"
              alt="Get Started">
          </div>

          <h1 class="black--text font-weight-bold">Reis efficiënter met jouw eigen privéchauffeur</h1>
        </div>

        <div>
          <v-btn
            class="btn-submit white--text"
            color="black"
            block
            @click="submit">
            Start hier
            <v-icon>arrow_forward</v-icon>
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
  <div
    v-else
    class="full-height">
    <div
      :style="{ background: loginScreenStyle.background }"
      class="session-wrapper login-screen column-layout justify-center">
      <v-container
        grid-list-xl
        class="column-layout justify-center">
        <div class="text-xs-center">
          <div class="session-table-cell row-layout-center">
            <div class="session-content login-content">
              <div class="login-logo text-xs-left">
                <img
                  :src="require('../../../assets/images/logo.svg')"
                  alt="Drive Me">
              </div>
              <div class="white-18 text-upper text-xs-left mt-5">
                Met DriveMe haal je meer uit je dag
              </div>
              <div class="mt-3">
                <v-btn
                  id="passenger-signup-description"
                  large
                  block
                  round
                  dark
                  color="pink"
                  class="login-button"
                  @click="submit">Start hier</v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>

  </div>
</template>

<script>
import request from '../../../helpers/request'
import { getEnvVersion } from '../../../helpers/helpers'

export default {
  mixins: [request],
  props: {
    userData: {
      type: Object,
      default: () => { return {} }
    },
    ride: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      isPassenger: false
    }
  },
  computed: {
    loginScreenStyle () {
      return {
        background: '#004253'
      }
    }
  },
  beforeMount () {
    this.isPassenger = getEnvVersion().siteVersion === 'ROLE_PASSENGER'
  },
  methods: {
    submit () {
      this.$emit('nextStep')
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    margin-bottom: 50px;
  }

  .login-content {
      width: 90%;
      max-width: 350px;
  }

  .login-logo {
      margin-bottom: 5%;
      margin-top: 0px;
  }

  .sign-up-wrapper {
    height: 100%;
    background-color: #ffffff;

    .container {
      height: 100%;

      .custom-container {
        min-height: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;

        .intro-img {
          border-radius: 50%;
          margin: 10px 0;

            @media(max-width:700px){
              width: 75%;
            }
        }

        h1 {
          line-height: 35px;
          margin-bottom: 20px;
        }

        .btn-submit {
          text-transform: none;
        }
      }

      @media not all and (min-resolution:.001dpcm) {
        @supports (-webkit-appearance:none) {
          .custom-container {
            min-height: -webkit-fill-available !important;
          }
        }
      }
    }
  }
</style>
