const initChat = () => {
  // eslint-disable-next-line
  var Tawk_API = Tawk_API || {}
  // eslint-disable-next-line
  var Tawk_LoadStart = new Date()
  // eslint-disable-next-line
  ;(function() {
    // eslint-disable-next-line
    var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0]
    s1.async = true
    s1.id = 'talk-chat-script'
    s1.src = 'https://embed.tawk.to/5f940789194f2c4cbeb8a0f3/default'
    s1.charset = 'UTF-8'
    s1.setAttribute('crossorigin', '*')
    s0.parentNode.insertBefore(s1, s0)
  })()
}

export { initChat }
