const SIGN_UP_EMAIL = 'SignUpEmail'
const SIGN_UP_GET_STARTED = 'SignUpGetStarted'
const SIGN_UP_PHONE = 'SignUpPhone'
const SIGN_UP_FIRST_LAST_NAMES = 'SignUpFirstLastNames'
const SIGN_UP_ACCOUNT_CREATED_CONFIRMATION = 'SignUpAccountCreatedConfirmation'

export {
  SIGN_UP_GET_STARTED,
  SIGN_UP_EMAIL,
  SIGN_UP_PHONE,
  SIGN_UP_FIRST_LAST_NAMES,
  SIGN_UP_ACCOUNT_CREATED_CONFIRMATION
}
