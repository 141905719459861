import request from './request'

const SECRETARY_INVITE_STATUS = {
  INVITED: 'invited',
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

const SECRETARY_INVITE_STATUS_DUTCH = {
  [SECRETARY_INVITE_STATUS.INVITED]: 'uitgenodigd',
  [SECRETARY_INVITE_STATUS.ACTIVE]: 'actief',
  [SECRETARY_INVITE_STATUS.INACTIVE]: 'inactief'
}

const secretaryInviteStatuses = Object.keys(SECRETARY_INVITE_STATUS).map(statusKey => SECRETARY_INVITE_STATUS[statusKey])

export default {
  data () {
    return {
      SECRETARY_INVITE_STATUS,
      SECRETARY_INVITE_STATUS_DUTCH,
      secretaryInviteStatuses
    }
  },
  mixins: [request],
  methods: {
    async getUserSecretaries (userId) {
      return this.request('GET', '/secretaries', {
        params: { client: userId, pageSize: 500 }
      })
    },
    async deleteSecretary (id) {
      return this.request('DELETE', `/secretaries/${id}`, {})
    },
    async secretaryInviteApprove (token) {
      let body = {
        data: {
          token
        }
      }

      return this.request('POST', `/secretary-invite/${token}/approve`, body)
    },
    async secretaryInviteReject (token) {
      let body = {
        data: {
          token
        }
      }

      return this.request('POST', `/secretary-invite/${token}/reject`, body)
    },
    async getSecretaryInvite (token) {
      return this.request('GET', `/secretary-invite/${token}`, {})
    },
    // { email, firstName, lastName }
    async sendSecretaryInvite (reqBody) {
      let body = {
        data: reqBody
      }

      return this.request('POST', '/secretary-invite', body)
    },
    async updateSecretary (id, reqBody) {
      let body = {
        data: reqBody
      }

      return this.request('PATCH', `/secretaries/${id}`, body)
    },
    async resendSecretaryInvite (id) {
      return this.request('POST', `/secretaries/${id}/re-invite`, {})
    }
  }
}
