<template>
  <div
    v-if="isPassenger"
    class="sign-up-confirmation-wrapper">
    <v-container grid-list-xl>
      <div class="custom-container text-center">
        <div>
          <img
            class="confirmation-img"
            src="../../../assets/images/confirmation.jpg"
            alt="Confirmation">
          <h1 class="black--text font-weight-bold">Gefeliciteerd! Je account is aangemaakt. Boek nu je eerste rit.</h1>
        </div>

        <div class="custom-link lightBlue--text mt-2 mb-3">
          We gebruiken je gegevens voor jouw boeking en het factureren van je rit. Bekijk onze <a
            href="https://rentabob.nl/over-ons/algemene-voorwaarden/"
            class="privacy-policy custom-link lightBlue--text"
            target="_blank">privacy policy</a> voor meer info.
        </div>

        <div>
          <v-btn
            class="btn-submit white--text"
            color="black"
            block
            @click="onOkClick()">
            Volgende
            <v-icon>arrow_forward</v-icon>
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>

  <div
    v-else
    class="full-height">
    <div
      class="view-confirmation-wrapper"
    >
      <div
        class="view-confirmation-content"
      >
        <div class="blue-bar-scoped white-15 text-upper row-layout align-center justify-center">
          Gefeliciteerd! Je account is aangemaakt
        </div>
        <img
          src="../../../assets/images/conf-ride-image.png"
          class="img-responsive mb-3 confirm-image"
          width="120px"
          height="120px"
        >
        <div class="confrim-text-wrapper">
          <!-- <div>
            Zodra een chauffeur reageert op jouw rit ontvang je een e-mail op {{ $store.getters.getUser.email }}
          </div>
          <div class="asnwers-text">
            Maak vervolgens een keuze uit de reacties
          </div> -->

          <v-btn
            id="signup-create-ride-confirm-button"
            large
            block
            round
            dark
            color="pink"
            class="next-button px-3 confirm-btn"
            @click="onOkClick"
          >
            OK
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEnvVersion } from '../../../helpers/helpers'

export default {
  beforeMount () {
    this.isPassenger = getEnvVersion().siteVersion === 'ROLE_PASSENGER'
  },
  methods: {
    onOkClick (e) {
      if (e) e.preventDefault()
      window.location = '/rides'
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-field-mobile {
    /deep/ input {
      width: 224px !important;
    }
  }

  .loading {
    opacity: 0.7;
  }

  .price-wrapper {
    margin-bottom: 100px;
  }

  .sticky {
    z-index: 75;
  }

  .text-field-desk {
    @media(min-width: 1264px){
      max-width: 400px;
    }
  }

  .hide-lg {
    @media(min-width: 1264px){
      display: none;
    }
  }

  .hide-arrow {
    @media(min-width: 1264px){
      position: absolute;
      z-index: 50;
      right: 0;
      background-color: white;
      height: 30px;
      width: 25px;
    }
  }

  .app-card-content {
    height: auto;
  }

  .blue-bar-scoped {
    color: white;
    background: #004253;
    width: 100%;
  }

  .view-confirmation-wrapper {
    height: 100%;
    color: white;
    background: #004253;
    padding: 0 1.25rem;
  }

  .view-confirmation-content {
    margin: auto;
    padding-top: 50px;
    max-width: 400px;
    text-align: center;
  }

  .full-height {
    height: 100%;
  }

  .confirm-image {
    margin-top: 30px;
    border-radius: 50%;
  }

  .confrim-text-wrapper {
    margin: 10px 0 50px;
  }

  .asnwers-text {
    margin-top: 25px;
  }

  .confirm-btn {
    max-width: 250px;
    margin: 50px auto 10px;
  }

  .sign-up-confirmation-wrapper {
    height: 100%;
    background-color: white;

    .container {
      height: 100%;

      .custom-container {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .confirmation-img {
          border-radius: 50%;
          margin: 10px 0;

            @media(max-width:700px){
              width: 75%;
            }
        }

        .custom-title {
          margin-top: 40px;
        }

        h1 {
          line-height: 23px;
          font-size: 20px;
        }

        .privacy-policy {
          text-decoration: underline;
        }
      }
    }
  }
</style>
