<template>
  <div >
    <div class="blue-bar-scoped white-15 text-upper row-layout align-center justify-center">
      {{ header }}
    </div>
    <div>
      <v-container
        v-if="!googleStaticMapUrl"
        grid-list-xl
      >
        <div class="card-wrapper">
          <app-card class="card-scoped">
            <h4 class="dark-blue-16 text-upper row-layout align-start">
              <v-btn
                v-if="isShowBackIcon"
                :id="backButtonId"
                class="mx-0 mt-0 pt-0"
                flat
                icon
                @click="$emit('stepBack')"
              >
                <v-icon v-if="webFontsDisabled === false">
                  arrow_back_ios
                </v-icon>
                <span
                  v-if="webFontsDisabled"
                  class="back-icon"
                >
                  &#706;
                </span>
              </v-btn>
              <div class="back-button-text-scoped">
                {{ backText }}
              </div>
            </h4>
            <div>
              <slot />
            </div>
            <div :class="isSubmitBtnWrapperClass ? 'submit-button-wrapper' : ''">
              <v-btn
                :loading="buttonLoader"
                :id="submitButtonId"
                large
                block
                round
                dark
                color="pink"
                class="next-button px-3"
                @click="$emit('submitButton')"
              >
                {{ buttonText || 'VOLGENDE' }}
                <v-icon
                  v-if="isShowSubmitBtIcon && webFontsDisabled === false"
                  right
                  size="19"
                >
                  arrow_forward_ios
                </v-icon>
              </v-btn>
              <div
                v-if="isShowBtnSkip"
                text
                class="skip-btn-wrapper"
              >
                <div
                  :id="skipButtonId"
                  class="dark-blue-14 skip-btn"
                  @click="$emit('skipBtnClick')"
                >
                  {{ buttonSkipText || 'Ik will eerst even rondkijken' }}
                </div>
              </div>

            </div>
            <div>
              <slot name="under-button" />
            </div>
          </app-card>
        </div>
      </v-container>
      <div
        v-else
        class="map-block">
        <v-container
          grid-list-xl
        >
          <div
            class="card-wrapper-map">
            <app-card class="card-scoped-map">
              <h4 class="dark-blue-16 text-upper row-layout align-start">
                <v-btn
                  v-if="isShowBackIcon"
                  :id="backButtonId"
                  class="mx-0 mt-0 pt-0"
                  flat
                  icon
                  @click="$emit('stepBack')"
                >
                  <v-icon v-if="webFontsDisabled === false">
                    arrow_back_ios
                  </v-icon>
                  <span
                    v-if="webFontsDisabled"
                    class="back-icon"
                  >
                    &#706;
                  </span>
                </v-btn>
                <div class="back-button-text-scoped">
                  {{ backText }}
                </div>
              </h4>
              <div>
                <slot />
              </div>
              <div :class="isSubmitBtnWrapperClass ? 'submit-button-wrapper' : ''">
                <v-btn
                  :loading="buttonLoader"
                  :id="submitButtonId"
                  large
                  block
                  round
                  dark
                  color="pink"
                  class="next-button px-3"
                  @click="$emit('submitButton')"
                >
                  {{ buttonText || 'VOLGENDE' }}
                  <v-icon
                    v-if="isShowSubmitBtIcon && webFontsDisabled === false"
                    right
                    size="19"
                  >
                    arrow_forward_ios
                  </v-icon>
                </v-btn>
                <div
                  v-if="isShowBtnSkip"
                  text
                  class="skip-btn-wrapper"
                >
                  <div
                    :id="skipButtonId"
                    class="dark-blue-14 skip-btn"
                    @click="$emit('skipBtnClick')"
                  >
                    {{ buttonSkipText || 'Ik will eerst even rondkijken' }}
                  </div>
                </div>

              </div>
              <div>
                <slot name="under-button" />
              </div>
            </app-card>

            <div
              v-if="googleStaticMapUrl"
              class="map-wrapper">
              <div
                id="google-map"
                :style="{ backgroundImage: 'url(' + googleStaticMapUrl + ')' }"
              />
            </div>

            <div
              v-if="showInfoBlock"
              class="info-wrapper"
            >
              <div>
                VRAGEN?
              </div>
              <div class="info-telephon">
                Telefoon: 0850443733
              </div>
              <div class="info-work-time">
                Wij zijn bereikbaar op werkdagen
                van 09:00 – 18:00
              </div>
            </div>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backText: {
      type: String,
      default: ''
    },
    header: {
      type: String,
      default: ''
    },
    buttonLoader: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: ''
    },
    isShowBtnSkip: {
      type: Boolean,
      default: false
    },
    isShowSubmitBtIcon: {
      type: Boolean,
      default: true
    },
    isShowBackIcon: {
      type: Boolean,
      default: true
    },
    buttonSkipText: {
      type: String,
      default: ''
    },
    isSubmitBtnWrapperClass: {
      type: Boolean,
      default: false
    },
    backButtonId: {
      type: String,
      default: 'back-button'
    },
    submitButtonId: {
      type: String,
      default: 'submit-button'
    },
    skipButtonId: {
      type: String,
      default: 'skip-button'
    },
    googleStaticMapUrl: {
      type: String,
      default: ''
    },
    showInfoBlock: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      webFontsDisabled: false
    }
  },
  mounted () {
    this.webFontsDisabled = this.isWebFontsDisabled()
  }
}
</script>

<style lang="scss" scoped>
    .blue-bar-scoped {
        color: white;
        background: #004253;
        height: 67px;
        width: 100%;
    }
    .card-scoped {
        /deep/ .app-card {
            margin: auto;
            min-height: 460px;
            max-width: 400px;

            @media(min-width:960px){
              max-width: 655px;
            }
        }
        /deep/ .app-card-content {
            min-height: 460px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

          @media(min-width:960px){
            min-width: 655px;
          }
        }
    }
    .map-block {
      /deep/ .grid-list-xl {
        padding: 0;
      }
    }
    .card-scoped-map {
        /deep/ .app-card {
            margin: auto;
            min-height: 300px;

            @media(min-width:960px){
              max-width: 400px;
              min-height: calc(100vh - 67px);
            }
        }
        /deep/ .app-card-content {
            min-height: 300px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

          @media(min-width:960px){
            min-height: 460px;
            min-width: 400px;
          }
        }
    }

    .info-telephon {
      margin-top: 4px;
      font-size: 12px;
      margin-bottom: 25px;
    }

    .info-work-time {
      font-size: 12px;
      line-height: 1.8;
    }

    .info-wrapper {
      @media(max-width: 1263px){
        display: none;
      }

      padding: 30px;
      align-self: flex-end;
      margin-left: 28px;
      margin-right: 15%;
      height: 166px;
      width: 313px;
      background-color: rgba(0, 66, 83, 0.2);
      color: #ffffff;
    }

    .next-button {

      @media(max-width: 700px){
        position: fixed;
        bottom: 17px;
        right: 15px;
        left: auto;
        width: 220px;
      }

       .v-icon {
        position: absolute;
        right: 0;

        @media(max-width: 700px){
          right: 10px;
        }
       }
    }
    .back-button-text-scoped {
        line-height: 24px;
        margin-top: 4px;
    }
    .skip-btn-wrapper {
      margin-top: 20px;
      text-align: center;
    }
    .skip-btn {
      display: inline-block;
      padding: 10px;
      cursor: pointer;
    }
    .card-wrapper {
      @media(min-width:960px){
        display: flex;
        justify-content: center;
      }
    }
    .card-wrapper-map {
      @media(min-width:960px){
        display: flex;
        justify-content: flex-start;
        min-height: calc(100vh - 67px);
      }
    }
    .submit-button-wrapper {
      // TODO
      // @media(max-width: 1264px){
      //   position: fixed;
      //   z-index: 50;
      //   bottom: 40px;
      //   right: 35px;
      //   left: 35px;
      // }
    }
    .back-icon {
      margin-top: 13px;
      font-size: 40px;
    }

    #google-map {
      height: 400px;
      width: 100%;
      margin: auto;
      background-size: cover;
      background-position: center;

      @media(min-width:960px){
        height: 100%;
      }
    }

    .map-wrapper {
      width: 100%;
    }
</style>
