<template>
  <div
    v-if="isPassenger"
    class="sign-up-user-wrapper">
    <v-container grid-list-xl>
      <v-btn
        class="btn-back"
        flat
        icon
        @click="onStepBack()"
      >
        <v-icon color="dark">
          arrow_back
        </v-icon>
      </v-btn>
      <div class="custom-container">
        <div>
          <div class="custom-title black--text mb-3">Wat is je naam?</div>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent>
            <v-text-field
              v-model="firstName"
              :rules="nameRules"
              :max-length="TEXT_FIELD_MAX_LENGTH"
              color="black"
              background-color="ashyGray"
              class="custom-input py-0"
              type="text"
              placeholder="Voornaam"
              required
              @keyup.enter="validateForm()"
            />
            <v-text-field
              v-model="lastName"
              :max-length="TEXT_FIELD_MAX_LENGTH"
              color="black"
              background-color="ashyGray"
              class="custom-input py-0"
              type="text"
              placeholder="Achternaam"
              @keyup.enter="validateForm()"
            />
          </v-form>
          <div>
            <v-btn
              :loading="loading"
              class="btn-submit white--text"
              color="black"
              type="submit"
              block
              @click="validateForm()">
              Volgende
              <v-icon>arrow_forward</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-container>
  </div>

  <div v-else>
    <blu-bar-card
      :button-loader="loading"
      back-text="Wat is je naam?"
      header="jouw gegevens - Stap 2/4"
      back-button-id="signup-first-last-name-back"
      submit-button-id="signup-first-last-name-submit"
      skip-button-id="signup-first-last-name-skip"
      show-info-block
      @submitButton="validateForm()"
      @stepBack="onStepBack">
      <div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-text-field
            v-model="firstName"
            :rules="nameRules"
            :max-length="TEXT_FIELD_MAX_LENGTH"
            class="red-validation pt-0 underline-input-black"
            color="darkBlue"
            type="text"
            label="Voornaam"
            required
          />
          <v-text-field
            v-model="lastName"
            :max-length="TEXT_FIELD_MAX_LENGTH"
            class="red-validation mt-3 underline-input-black"
            color="darkBlue"
            type="text"
            label="Achternaam"
          />
        </v-form>
      </div>
    </blu-bar-card>
  </div>
</template>

<script>
import { TEXT_FIELD_MAX_LENGTH } from '../../../constants/common'
import BluBarCard from '../../../modules/layout/BlueBarCard.vue'
import request from '../../../helpers/request'
import { getEnvVersion } from '../../../helpers/helpers'
import Secretary from '../../../helpers/secretary'

export default {
  components: { BluBarCard },
  mixins: [request, Secretary],
  props: {
    userData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      TEXT_FIELD_MAX_LENGTH,
      loading: false,
      valid: true,
      firstName: '',
      lastName: '',
      nameRules: [
        v => !!v || 'Is verplicht',
        v => v.length > 0 || 'Vul je name in'
      ],
      isPassenger: false
    }
  },
  beforeMount () {
    this.userData.firstName != null && (this.firstName = this.userData.firstName)
    this.userData.lastName != null && (this.lastName = this.userData.lastName)
    this.isPassenger = getEnvVersion().siteVersion === 'ROLE_PASSENGER'
  },
  methods: {
    onStepBack () {
      this.$emit('stepBack')
    },
    validateForm () {
      this.saveData(() => {
        if (this.$refs.form.validate()) {
          this.$emit('nextStep')
        }
      })
    },
    saveData (callback) {
      let body = { data: {} }

      if (this.firstName.length > 0) body.data.firstName = this.firstName
      if (this.lastName.length > 0) body.data.lastName = this.lastName

      this.request('PATCH', '/sign-up', body, ({ data }) => {
        this.$emit('updateUserData', { param: 'firstName', val: this.firstName })
        this.$emit('updateUserData', { param: 'lastName', val: this.lastName })

        this.request('GET', '/me', {}, ({ data }) => {
          const dataWithPhone = {
            ...data,
            phone: data.phone && data.phone.number ? data.phone.number : data.phone
          }

          this.$store.dispatch('loginUser', { data: dataWithPhone })
          this.$store.dispatch('setUser', dataWithPhone)

          const pessengerBody = {
            data: {
              user: this.$store.getters.getUser.id
            }
          }

          this.request('POST', '/passengers', pessengerBody, async ({ data }) => {
            const secretaryInviteToken = localStorage.getItem('secretaryInviteToken')

            try {
              if (secretaryInviteToken) {
                await this.secretaryInviteApprove(secretaryInviteToken)
                localStorage.removeItem('secretaryInviteToken')
                localStorage.removeItem('secretaryEmail')
              }

              if (callback) {
                callback()
              }
            } catch (error) {

            }
          }, null, (error = {}) => {
            if (error.type === 'passenger.already_passenger') {
              if (callback) {
                callback()
              }
            }
          }, true)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .sign-up-user-wrapper {
    height: 100%;
    background-color: white;

    .btn-back {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    .container {
      height: 100%;

      .custom-container {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .custom-title {
          margin-top: 40px;
        }
      }
    }
  }
</style>
