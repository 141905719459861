<template>
  <div
    v-if="showBackgroundImage()"
    :class="`layout-wrapper ${isPassenger ? 'layout-passenger-wrapper' : ''}`" >
    <component
      :is="currentScreen"
      :user-data.sync="userData"
      :ride.sync="ride"
      :prev-step="prevStep"
      @stepBack="onStepBack"
      @nextStep="onStepNext"
      @updateUserData="updateUserData"
      @updateRide="updateRide"
    />
  </div>
  <div
    v-else
    :class="layoutWrapperCommon">
    <component
      :is="currentScreen"
      :user-data.sync="userData"
      :ride.sync="ride"
      :prev-step="prevStep"
      @stepBack="onStepBack"
      @nextStep="onStepNext"
      @updateUserData="updateUserData"
      @updateRide="updateRide"
    />
  </div>

</template>

<script>
import SignUpEmail from './SignUpPages/SignUpEmail.vue'
import SignUpGetStarted from './SignUpPages/SignUpGetStarted.vue'
import SignUpFirstLastNames from './SignUpPages/SignUpFirstLastNames.vue'
import SignUpPhone from './SignUpPages/SignUpPhone.vue'
import SignUpAccountCreatedConfirmation from './SignUpPages/SignUpAccountCreatedConfirmation.vue'

import Request from '../../helpers/request'
import { addHashParamToUrl, getHashParamValue } from '../../helpers/url'
import { clearToken } from '../../helpers/tokenHelper'
import * as SIGN_UP_STEPS from '../../helpers/sign-up-steps'
import { initChat } from '../../helpers/chat'
import { getEnvVersion } from '../../helpers/helpers'

export default {
  components: {
    SignUpEmail, SignUpGetStarted, SignUpFirstLastNames, SignUpPhone, SignUpAccountCreatedConfirmation
  },
  mixins: [Request],
  data () {
    return {
      layoutWrapperCommon: 'layout-wrapper-common-blurred',
      userData: {
        firstName: '',
        lastName: '',
        companyName: '',
        address: '',
        phone: '',
        email: '',
        description: '',
        reference: '',
        userId: '',
        passengerId: ''
      },
      ride: {
        id: null,
        startAt: '',
        endAt: '',
        stops: [],
        note: '',
        insurance: false,
        isSkipped: false
      },
      loginLoader: false,
      isPassenger: false,
      checkbox: false,
      password: '',
      passwordRules: [v => !!v || 'Vul je wachtwoord in'],
      steps: [
        SIGN_UP_STEPS.SIGN_UP_GET_STARTED,
        SIGN_UP_STEPS.SIGN_UP_PHONE,
        SIGN_UP_STEPS.SIGN_UP_EMAIL,
        SIGN_UP_STEPS.SIGN_UP_FIRST_LAST_NAMES,
        SIGN_UP_STEPS.SIGN_UP_ACCOUNT_CREATED_CONFIRMATION
      ],
      currentStep: 0,
      currentScreen: 'SignUpGetStarted',
      prevStep: 0,
      address: ''
    }
  },
  beforeMount () {
    this.isPassenger = getEnvVersion().siteVersion === 'ROLE_PASSENGER'

    if (localStorage.getItem('isAuth')) {
      this.request('GET', '/me', {}, ({ data }) => {
        if (data) {
          this.request('GET', '/storage', {}, async (rideRes) => {
            if (rideRes.data.data.length) {
              this.ride = {
                ...this.ride,
                ...rideRes.data.data[0].data
              }
            }

            if (getEnvVersion().siteVersion !== 'ROLE_PASSENGER') {
              await this.killToken()
              clearToken()
              return this.$router.push('/session/login')
            }

            this.$store.dispatch('loginUser', { data })
            this.$store.dispatch('setUser', data)

            if (!data.roles.includes('ROLE_SIGN_UP')) {
              window.location = '/rides'
              return
            }

            this.updateUserData({ param: 'companyName', val: data.companyName || '' })
            this.updateUserData({ param: 'firstName', val: data.firstName || '' })
            this.updateUserData({ param: 'lastName', val: data.lastName || '' })
            this.updateUserData({ param: 'address', val: data.address || '' })
            this.updateUserData({ param: 'phone', val: data.phone && data.phone.number ? data.phone.number : data.phone })
            this.updateUserData({ param: 'email', val: data.email || '' })
            this.updateUserData({ param: 'userId', val: data.userId || '' })

            const stepNumber = parseFloat(getHashParamValue('step'))

            if (stepNumber) {
              addHashParamToUrl('step', stepNumber)
              this.setCurrentScreen()
            } else {
              addHashParamToUrl('step', 1)
            }

            this.layoutWrapperCommon = 'layout-wrapper-common'
          })
        }
      }, null, async () => {
        await this.killToken()
        clearToken()
      })
    } else {
      this.layoutWrapperCommon = 'layout-wrapper-common'
      window.location.hash = `#step=0`
    }
  },
  mounted () {
    this.handleCoupon()
    this.checkCoupon()

    // if back button is pressed
    // window.onpopstate = function (event) {
    //   alert('location: ' + document.location + ', state: ' + JSON.stringify(event.state))
    // }
    const CHAT_TIME_OUT = screen.width <= 760 ? 10000 : 5000

    setTimeout(() => {
      initChat()
    }, CHAT_TIME_OUT)

    window.onhashchange = () => {
      this.setCurrentScreen()
    }
  },
  methods: {
    showBackgroundImage () {
      return ![SIGN_UP_STEPS.SIGN_UP_EMAIL, SIGN_UP_STEPS.SIGN_UP_GET_STARTED].includes(this.currentScreen)
    },
    handleCoupon () {
      if (this.$route.query.coupon) {
        if (!localStorage.getItem(this.$route.query.coupon)) {
          localStorage.setItem(this.$route.query.coupon, this.$route.query.coupon)
        }
      }
    },
    checkCoupon () {
      if (this.$route.query.coupon) {
        const storedCoupon = localStorage.getItem(this.$route.query.coupon)

        if (storedCoupon) {
          this.updateUserData({ param: 'reference', val: storedCoupon })
        }
      }
    },
    updateUserData (val) {
      this.userData[val.param] = val.val
    },
    updateRide (val) {
      this.ride[val.param] = val.val
    },
    getStepNumber () {
      return parseFloat(getHashParamValue('step')) || this.currentStep
    },
    onStepBack () {
      const stepNumber = this.getStepNumber()
      this.prevStep = stepNumber

      if (stepNumber) {
        addHashParamToUrl('step', stepNumber - 1)
      }
    },
    onStepNext () {
      const stepNumber = this.getStepNumber()

      addHashParamToUrl('step', stepNumber + 1)
    },
    setCurrentScreen () {
      const stepNumber = this.getStepNumber()
      this.currentScreen = this.steps[stepNumber]
    }
  }
}
</script>

<style lang="scss" scoped>
.login-content {
  width: 90%;
  max-width: 350px;
}
.login-logo {
  margin-bottom: 7%;
}
.background-cover {
  background-size: cover !important;
}
.forgot-password-link {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin-top: -16px;
}

@keyframes blur_animate {
    0% { filter: blur(10px);}
    50% { filter: blur(5px);}
    100% { filter: blur(0px);}
}

.layout-wrapper-common {
  min-height: 100vh;
  // animation: blur_animate 1s;
}

.layout-wrapper-common-blurred {
  min-height: 100vh;
  filter: blur(10px);
}

.layout-wrapper {
  height: 100%;

  @media(max-width: 700px){
    padding-bottom: 90px;
  }

  @media(min-width: 1264px){
    background-image: url(/static/images/backgrounds/signup-background-image.jpg);
    background-size: cover;
    background-position: center;
    height: 100%;
  }

  &.layout-passenger-wrapper {
    background-image: none;
    padding-bottom: 0;
  }
}

/deep/ .v-label--active.primary--text {
  color: white !important;
}
</style>
